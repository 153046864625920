import React from "react";
import { useSelector } from "react-redux";
// Styles
import "./configContentHeader.module.css";
// Custom Components
import ConfigContentHeaderMain from "./components/configContentHeaderMain";
import ConfigContentHeaderEdit from "./components/configContentHeaderEdit";
import SiteBreadcrumb from "../siteBreadcrumb/SiteBreadcrumb";
// Contexts
import { useTabActionsContext } from "../../contexts/TabActionsContext";
// Store
import { creationOrEditionMode } from "../../store/root";

/**
 * Component that handles some common actions to all the configuration sub navs
 * @returns JSX.Element
 */
const ConfigContentHeader = (): JSX.Element => {
  // REDUX Root slice
  const isCreationOrEditionMode = useSelector(creationOrEditionMode);

  const { disableAllButtons } = useTabActionsContext();

  return (
    <header className="config-content__header">
      {isDMS && <SiteBreadcrumb />}
      <div
        data-cr={isCreationOrEditionMode ? "edit-panel" : "main-panel"}
        className={`config-content__actions${
          disableAllButtons ? " disabled" : ""
        }`}
      >
        {isCreationOrEditionMode ? (
          <ConfigContentHeaderEdit disableActions={disableAllButtons} />
        ) : (
          <ConfigContentHeaderMain disableActions={disableAllButtons} />
        )}
      </div>
    </header>
  );
};

export default ConfigContentHeader;
