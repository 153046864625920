// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useI18n } from "compass-commons";
// Components
import { AutoComplete, OptionType } from "dms-lib";
// Styles
import "../behaviourTriggerPanel.module.css";
// Models
import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";
import {
  ResourceMappingConnectionsDto,
  ConnectionDetails,
} from "../../../../../models/resourceMapping/ResourceMappingConnectionsDto";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";

interface ConnectionOption extends OptionType {
  name: string;
}

interface BehaviourTriggerConnectionNameProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  errors?: FormErrors<FormRuleDto>;
  connections: ResourceMappingConnectionsDto;
  loading: boolean;
  handleConnectionSelect: (connection: ConnectionDetails) => void;
}

const BehaviourTriggerConnectionName = (
  props: BehaviourTriggerConnectionNameProps
): JSX.Element => {
  const {
    ruleId,
    readOnlyMode,
    control,
    errors,
    loading,
    connections,
    handleConnectionSelect,
  } = props;
  const { t: translate } = useI18n();

  return (
    <div className="config-incidents__trigger-input__section">
      <span>
        {translate("behaviors.triggerFieldsDescription.connectionName")}
      </span>
      <div className="config-incidents__trigger-input__controller">
        <Controller
          name="trigger.eventTypeSubsystem"
          control={control}
          render={({ field: { onChange, ...field } }) => {
            return (
              <AutoComplete<ConnectionOption>
                {...field}
                value={field.value as ConnectionOption}
                dataCr="incident-trigger-connection-name"
                options={connections.connections}
                getOptionLabel={(option) => option?.name || ""}
                id={`trigger-connection-name-autocomplete-${ruleId}`}
                disabled={readOnlyMode}
                loading={loading}
                placeholder={translate(
                  "behaviors.triggerFieldsDescription.connectionPlaceholder"
                )}
                onChangeCallback={(data: ConnectionDetails, _e) => {
                  handleConnectionSelect(data);
                  onChange(data);
                }}
                error={!!errors.trigger?.eventTypeSubsystem}
                errorMessage={translate(
                  errors.trigger?.eventTypeSubsystemName?.message ||
                    errors.trigger?.eventTypeSubsystem?.message ||
                    errors.trigger?.message,
                  { label: translate("behaviors.fields.connectionName") }
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default BehaviourTriggerConnectionName;
