// General
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
import { stringify } from "qs";
// Models
import { ResourceMappingDto } from "../models/resourceMapping/ResourceMappingDto";
import { ResourceMappingConnectionsDto } from "../models/resourceMapping/ResourceMappingConnectionsDto";
import { ResourceMappingEventTypesDto } from "../models/resourceMapping/ResourceMappingEventTypesDto";
import { ResourceMappingFilters } from "../models/resourceMapping/ResourceMappingFilters";
// Utils
import { buildErrorMessage } from "../utils/Util";

const { RESOURCE_MAPPING_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_RESOURCE_MAPPINGS: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping`,
  GET_CONNECTIONS: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/connections-configuration/connections`,
  GET_EVENT_TYPES: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/connections-configuration/connections/(connectionId)/event-types`,
};

/**
 * Floor plan Service where backend service is called
 *
 */
export default class ResourceMappingService {
  static abortController: AbortController;

  static async getResourceMappings(
    resourceMappingFilters?: ResourceMappingFilters
  ): Promise<ResourceMappingDto[]> {
    this.abortController?.abort();
    this.abortController = new AbortController();

    const urlPath = `${URL_PATHS.GET_RESOURCE_MAPPINGS}`;
    return httpClient
      .get<ResourceMappingDto[]>({
        url: urlPath,
        config: {
          params: {
            ...resourceMappingFilters,
          },
          paramsSerializer: {
            serialize: (params) => stringify(params, { arrayFormat: "comma" }),
          },
          signal: this.abortController.signal,
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getConnections(): Promise<ResourceMappingConnectionsDto> {
    const urlPath = URL_PATHS.GET_CONNECTIONS;
    return httpClient
      .get<ResourceMappingConnectionsDto>({
        url: urlPath,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async getEventTypes(
    connectionId: string
  ): Promise<ResourceMappingEventTypesDto> {
    const urlPath = getUrl(URL_PATHS.GET_EVENT_TYPES, { connectionId });
    return httpClient
      .get<ResourceMappingEventTypesDto>({
        url: urlPath,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(buildErrorMessage(error));
      });
  }
}
