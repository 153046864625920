// General
import { createAsyncThunk } from "@reduxjs/toolkit";
// Services
import ResourceMappingService from "../../../services/ResourceMappingService";
// Models
import { ResourceMappingFilters } from "../../../models/resourceMapping/ResourceMappingFilters";

function createExtraActions() {
  const getResourceMappings = () => {
    return createAsyncThunk(
      `resourceMapping/getResourceMapping`,
      async (params?: ResourceMappingFilters) =>
        ResourceMappingService.getResourceMappings(params)
    );
  };

  const getLiteResourceMappings = () => {
    return createAsyncThunk(
      `resourceMapping/getLightResourceMappings`,
      // eslint-disable-next-line
      async (params?: ResourceMappingFilters) => {
        const result = await ResourceMappingService.getResourceMappings(params);
        return {
          data: result.map((rm) => ({
            id: rm.id,
            name: rm.name,
          })),
          filters: {
            pageIndex: params.pageIndex,
          },
        };
      }
    );
  };

  const getConnections = () => {
    return createAsyncThunk(`resourceMapping/getConnections`, async () =>
      ResourceMappingService.getConnections()
    );
  };

  const getEventTypes = () => {
    return createAsyncThunk(
      `resourceMapping/getEventTypes`,
      async (connectionId: string) =>
        ResourceMappingService.getEventTypes(connectionId)
    );
  };

  return {
    getResourceMappings: getResourceMappings(),
    getLiteResourceMappings: getLiteResourceMappings(),
    getConnections: getConnections(),
    getEventTypes: getEventTypes(),
  };
}

const resourceMappingExtraActions = createExtraActions();

export default resourceMappingExtraActions;
