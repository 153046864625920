// General
import React, { useEffect } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";
// Styles
import "./behaviourTriggerPanel.module.css";
// Store
import {
  selectIncidents,
  selectResourceMapping,
  useStoreDispatch,
} from "../../../../store";
// Components
import BehaviourTriggerConnectionName from "./components/BehaviourTriggerConnectionName";
import BehaviourTriggerEventType from "./components/BehaviourTriggerEventType";
import BehaviourTriggerResourceMapping from "./components/BehaviourTriggerResourceMapping";
// Dto
import { FormErrors } from "../../models/BehaviourFormDataTypes";
import { ConnectionDetails } from "../../../../models/resourceMapping/ResourceMappingConnectionsDto";
import { FormRuleDto } from "../../../../models/incidents/form/FormRuleDto";
import { TriggerDto } from "../../../../models/incidents/TriggerDto";
import { EResourceFunctionalityType } from "../../../../models/resourceMapping/ResourceMappingFunctionalityDto";
// Store
import {
  incidentsActions,
  RESOURCE_MAPPING_PAGE_LENGTH,
} from "../../../../store/incidents";
import { resourceMappingActions } from "../../../../store/resourceMapping";
import BehaviourTriggerWeekDay from "./components/BehaviourTriggerWeekDay";
import BehaviourTriggerEnabled from "./components/BehaviourTriggerEnabled";
import BehaviourTriggerDisabledComment from "./components/BehaviourTriggerDisabledComment";

import BehaviourTriggerOnDemand from "./components/BehaviourTriggerOnDemand";

interface BehaviourTriggerPanelProps {
  ruleId: string;
  selectedTrigger: TriggerDto;
  selectedSiteId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  reset: () => void;
  errors?: FormErrors<FormRuleDto>;
  setFieldValue: UseFormSetValue<FormRuleDto>;
  watchField: UseFormWatch<FormRuleDto>;
  resetField: (name: string, options?: any) => void;
  connectionId: string;
  enabledOnDemandFeature: boolean;
}

const BehaviourTriggerPanel = (
  props: BehaviourTriggerPanelProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    ruleId,
    selectedTrigger,
    selectedSiteId,
    readOnlyMode,
    control,
    reset,
    errors,
    setFieldValue,
    resetField,
    connectionId,
    watchField,
    enabledOnDemandFeature,
  } = props;

  const dispatch = useStoreDispatch();
  const {
    resourceMappings,
    loadingResourceMappings,
    searchTermsResourceMappings: { hasMore, searchText },
  } = useSelector(selectIncidents);

  const { eventTypes, loadingEventTypes, connections, loadingConnections } =
    useSelector(selectResourceMapping);

  const loadEventTypes = (eventConnectionId: string) => {
    dispatch(resourceMappingActions.getEventTypes(eventConnectionId));
  };

  const loadResourceMappings = (
    sourceConnectionId: string,
    searchTerms: {
      textValue?: string;
      pageIndex?: number;
    } = {},
    alwaysSearch = false // Usefull when searching on the input box and not doing the infinity scroll
  ) => {
    if (!connectionId) return;

    const { textValue = "", pageIndex = 0 } = searchTerms;

    if (alwaysSearch || !loadingResourceMappings) {
      dispatch(
        resourceMappingActions.getLiteResourceMappings({
          siteId: selectedSiteId,
          subsystemId: sourceConnectionId,
          hasFunctionalityType: [EResourceFunctionalityType.EVENT_SOURCE],
          searchText: textValue,
          pageSize: RESOURCE_MAPPING_PAGE_LENGTH,
          pageIndex,
        })
      );
    }
  };

  const resetEventTypes = () => {
    resetField("trigger.eventTypeId", {
      defaultValue: selectedTrigger?.eventTypeId || "",
    });
  };

  const resetResourceMappings = () => {
    resetField("trigger.triggerResourceMappingDTOs", {
      defaultValue: selectedTrigger?.triggerResourceMappingDTOs,
    });
  };

  const resetWeekDays = () => {
    resetField("trigger.daysOfWeek", {
      defaultValue: selectedTrigger?.daysOfWeek,
    });
  };

  const loadConnections = () => {
    dispatch(resourceMappingActions.getConnections());
    resetEventTypes();
    resetResourceMappings();
    resetWeekDays();
    if (selectedTrigger?.eventTypeSubsystemId) {
      loadEventTypes(selectedTrigger?.eventTypeSubsystemId);
    }
  };

  const handleSelectEnabled = (enabled: boolean) => {
    if (enabled)
      resetField("trigger.commentForTriggerBeingDisabled", {
        defaultValue: null,
      });
  };

  const handleSelectConnection = (connection: ConnectionDetails) => {
    setFieldValue(
      "trigger.eventTypeSubsystemId",
      connection?.connectionId || null
    );
    setFieldValue("trigger.eventTypeSubsystemName", connection?.name || null);

    if (connection) {
      resetEventTypes();
      resetWeekDays();
      resetResourceMappings();
      loadEventTypes(connection?.connectionId);
    }
  };

  const handleWeekDaysSelect = (weekdays: string[]) => {
    setFieldValue("trigger.daysOfWeek", [
      ...weekdays?.map((o) => o.trim().toUpperCase()),
    ]);
  };

  const handleOnDemandClick = (onDemand: boolean) => {
    if (onDemand) {
      dispatch(incidentsActions.resetEventTypes());
      dispatch(incidentsActions.resetResourceMappings());
      setFieldValue("trigger.availableForOnDemand", onDemand);
      setFieldValue("trigger.eventTypeSubsystem", null);
      setFieldValue("trigger.eventTypeSubsystemId", null);
      setFieldValue("trigger.eventTypeSubsystemName", null);
      setFieldValue("trigger.eventType", null);
      setFieldValue("trigger.eventTypeId", null);
      setFieldValue("trigger.eventTypeName", null);
      setFieldValue("trigger.triggerResourceMappingDTOs", []);
    } else if (selectedTrigger?.eventTypeSubsystemId) {
      const connectionSelected = connections.connections.find(
        (s) => s.connectionId === selectedTrigger?.eventTypeSubsystemId
      );
      setFieldValue("trigger.eventTypeSubsystem", connectionSelected);
      handleSelectConnection(connectionSelected);
    }
  };

  useEffect(() => {
    reset();
    if (!readOnlyMode) {
      loadConnections();
    }
  }, [ruleId, readOnlyMode]);

  return (
    <div>
      {ruleId ? (
        <div data-cr="config-incidents__trigger-panel">
          <div className="config-incidents__trigger-title">
            <b>2. {translate("behaviors.trigger")}</b>
            <BehaviourTriggerEnabled
              ruleId={ruleId}
              readOnlyMode={readOnlyMode}
              control={control}
              handleEnableSelect={handleSelectEnabled}
            />
          </div>
          <div className="config-incidents__trigger-panel">
            {watchField("trigger.disabled") && (
              <div className="config-incidents__trigger-input">
                <BehaviourTriggerDisabledComment
                  ruleId={ruleId}
                  readOnlyMode={readOnlyMode}
                  control={control}
                />
              </div>
            )}
            {enabledOnDemandFeature && (
              <div className="config-incidents__trigger-input">
                <BehaviourTriggerOnDemand
                  ruleId={ruleId}
                  readOnlyMode={readOnlyMode}
                  control={control}
                  handleEnableOnClick={handleOnDemandClick}
                />
              </div>
            )}
            {!(
              enabledOnDemandFeature &&
              watchField("trigger.availableForOnDemand")
            ) && (
              <>
                <div className="config-incidents__trigger-input">
                  <BehaviourTriggerConnectionName
                    ruleId={ruleId}
                    readOnlyMode={
                      readOnlyMode || watchField("trigger.availableForOnDemand")
                    }
                    control={control}
                    connections={connections}
                    loading={loadingConnections}
                    errors={errors}
                    handleConnectionSelect={handleSelectConnection}
                  />
                </div>
                <div className="config-incidents__trigger-input">
                  <BehaviourTriggerEventType
                    ruleId={ruleId}
                    readOnlyMode={
                      readOnlyMode || watchField("trigger.availableForOnDemand")
                    }
                    control={control}
                    eventTypes={eventTypes}
                    loading={loadingEventTypes}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    watchField={watchField}
                  />
                </div>
                <div className="config-incidents__trigger-input">
                  <BehaviourTriggerResourceMapping
                    ruleId={ruleId}
                    readOnlyMode={
                      readOnlyMode || watchField("trigger.availableForOnDemand")
                    }
                    control={control}
                    resourceMappings={resourceMappings}
                    loading={loadingResourceMappings}
                    errors={errors}
                    onClose={() =>
                      dispatch(incidentsActions.resetResourceMappings())
                    }
                    onOpen={() => loadResourceMappings(connectionId)}
                    onInputChange={(textValue: string) => {
                      dispatch(
                        incidentsActions.resourceMappingTextChanged(textValue)
                      );
                      loadResourceMappings(
                        connectionId,
                        {
                          textValue,
                        },
                        true
                      );
                    }}
                    infiniteScroll={{
                      pageLength: RESOURCE_MAPPING_PAGE_LENGTH,
                      hasMore,
                      fetchNext: (pageIndex?: number) =>
                        loadResourceMappings(connectionId, {
                          textValue: searchText,
                          pageIndex,
                        }),
                      isLoading: loadingResourceMappings,
                    }}
                  />
                </div>
              </>
            )}
            <div className="config-incidents__trigger-input">
              <BehaviourTriggerWeekDay
                ruleId={ruleId}
                readOnlyMode={readOnlyMode}
                control={control}
                errors={errors}
                handleWeekDaysSelect={handleWeekDaysSelect}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>{translate("behaviors.noBehaviorSelected")}</div>
      )}
    </div>
  );
};

export default BehaviourTriggerPanel;
