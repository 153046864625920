import React, {
  useState,
  createContext,
  useContext,
  PropsWithChildren,
} from "react";
import useTabActions from "../hooks/useTabActions";

export type ActionState = (extraActions?: ActionState) => void;

export interface MainActionsConfigState {
  showCreateButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showAssistantButton?: boolean;
}

export interface EditActionsConfigState {
  showSaveButton?: boolean;
  showCancelButton?: boolean;
}

export interface ActionsHandler {
  resetHandler?: (
    resetDisableButtons: boolean,
    extraActions?: ActionState
  ) => void;
  extraResetHandler?: ActionState;
  disableAllButtons?: boolean;
  disableSaveButton?: boolean;
  activateAll?: boolean;
  mainActionsAllowed?: boolean;
  configMainButtons?: MainActionsConfigState;
  editActionsAllowed?: boolean;
  configEditButtons?: EditActionsConfigState;
  setExtraResetHandler?: (handler: ActionState) => void;
  setActivateAll?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableAllButtons?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableSaveButton?: React.Dispatch<React.SetStateAction<boolean>>;
  setMainActionsAllowed?: React.Dispatch<React.SetStateAction<boolean>>;
  setConfigMainButtons?: React.Dispatch<
    React.SetStateAction<MainActionsConfigState>
  >;
  setEditActionsAllowed?: React.Dispatch<React.SetStateAction<boolean>>;
  setConfigEditButtons?: React.Dispatch<
    React.SetStateAction<EditActionsConfigState>
  >;
}

const TabActionsContext = createContext<ActionsHandler>({});

const useTabActionsContext = (): ActionsHandler =>
  useContext(TabActionsContext);

/**
 * A Provider that saves actions to be executed later
 * @param children
 * @returns ContentActionsContext.Provider
 */
const TabActionsProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const initialDisableAllButtons = false;
  const initialDisableSaveButton = false;
  const initialActivateAll = false;
  const initialMainActionsAllowed = false;
  const initialConfigMainButtonState = {
    showCreateButton: false,
    showEditButton: false,
    showDeleteButton: false,
  };
  const initialEditActionsAllowed = false;
  const initialConfigEditButtonState = {
    showSaveButton: false,
    showCancelButton: false,
  };

  const [disableAllButtons, setDisableAllButtons] = useState<boolean>(
    initialDisableAllButtons
  );

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(
    initialDisableSaveButton
  );

  const [activateAll, setActivateAll] = useState<boolean>(initialActivateAll);
  const [mainActionsAllowed, setMainActionsAllowed] = useState<boolean>(
    initialMainActionsAllowed
  );
  const [configMainButtons, setConfigMainButtons] =
    useState<MainActionsConfigState>(initialConfigMainButtonState);
  const [editActionsAllowed, setEditActionsAllowed] = useState<boolean>(
    initialEditActionsAllowed
  );
  const [configEditButtons, setConfigEditButtons] =
    useState<EditActionsConfigState>(initialConfigEditButtonState);

  const { setReadOnlyMode } = useTabActions();

  const [extraResetHandler, setExtraResetHandler] = useState<ActionState>();

  const resetHandler = (resetDisableButtons: boolean) => {
    setDisableAllButtons(!resetDisableButtons);
    setDisableSaveButton(false);
    setReadOnlyMode();
    // Reset all buttons when leaving a scene
    // Is responsable of each scene to activate the buttons allowed
    setActivateAll(false);
    setMainActionsAllowed(false);
    setEditActionsAllowed(false);
    setConfigEditButtons({
      showCancelButton: false,
      showSaveButton: false,
    });
    setConfigMainButtons({
      showCreateButton: false,
      showDeleteButton: false,
      showEditButton: false,
      showAssistantButton: false,
    });
  };

  return (
    <TabActionsContext.Provider
      value={{
        resetHandler,
        extraResetHandler,
        activateAll,
        disableAllButtons,
        disableSaveButton,
        mainActionsAllowed,
        configMainButtons,
        editActionsAllowed,
        configEditButtons,
        // Remember that setUseState is able to receive a function to update state
        // So that way our handlers have to prevent that
        setExtraResetHandler: (handler) => setExtraResetHandler(() => handler),
        setActivateAll,
        setDisableSaveButton,
        setDisableAllButtons,
        setMainActionsAllowed,
        setConfigMainButtons,
        setEditActionsAllowed,
        setConfigEditButtons,
      }}
    >
      {children}
    </TabActionsContext.Provider>
  );
};

export { TabActionsProvider, useTabActionsContext };
