// General
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
// Models
import { RuleLightDto } from "../models/incidents/RuleLightDto";
import { RuleDto } from "../models/incidents/RuleDto";
import { IncidentTypeDto } from "../models/incidents/IncidentTypeDto";
import {
  IncidentTypeRulesPageDto,
  IncidentTypeRulesRequestDto,
} from "../models/incidents/IncidentTypeRule";
import { IncidentTypeRequestDTO } from "../models/incidents/IncidentTypeRequestDTO";

const { INCIDENT_RULES_ENGINE_PATH } = appConfig;

const URL_PATHS = {
  RULES_ENGINE: `${INCIDENT_RULES_ENGINE_PATH}/incident-rules`,
  RULES_ENGINE_BY_ID: `${INCIDENT_RULES_ENGINE_PATH}/incident-rules/(ruleId)`,
  RULES_ENGINE_CREATE_TEST_INCIDENT: `${INCIDENT_RULES_ENGINE_PATH}/incident-rules/(ruleId)/create-test-incident`,
  INCIDENT_TYPES_ENGINE: `${INCIDENT_RULES_ENGINE_PATH}/incident-types`,
  INCIDENT_TYPES_ENGINE_BY_ID: `${INCIDENT_RULES_ENGINE_PATH}/incident-types/(incidentTypeId)`,
  INCIDENT_TYPES_FORWARD_ENGINE_BY_ID: `${INCIDENT_RULES_ENGINE_PATH}/incident-types/(incidentTypeId)/forwardRules/(incidentTypeIdToForward)`,
  INCIDENT_TYPE_RULES_ENGINE: `${INCIDENT_RULES_ENGINE_PATH}/incident-rules/incident-types/(incidentTypeId)`,
};

const httpClient = new HttpClient(appConfig);

export default class IncidentsService {
  static async getAllRules(siteId: string): Promise<RuleLightDto[]> {
    const urlPath = `${URL_PATHS.RULES_ENGINE}`;

    return httpClient
      .get<RuleLightDto[]>({
        url: urlPath,
        config: {
          params: {
            siteId,
          },
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getRuleById(ruleId: string): Promise<RuleDto> {
    const urlPath = getUrl(URL_PATHS.RULES_ENGINE_BY_ID, { ruleId });
    return httpClient
      .get<RuleDto>({
        url: urlPath,
      })
      .then((response) => {
        // TODO: Remove this when the API is fixed (eventTypeName can be null for old records)
        if (response?.trigger && !response.trigger?.eventTypeName) {
          response.trigger.eventTypeName = response.trigger.eventTypeId;
        }
        return response;
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async postRule(rule: RuleDto): Promise<string> {
    const urlPath = URL_PATHS.RULES_ENGINE;
    return httpClient
      .post<string>({
        url: urlPath,
        payload: rule,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async putRule(rule: RuleDto): Promise<RuleDto> {
    const urlPath = getUrl(URL_PATHS.RULES_ENGINE_BY_ID, {
      ruleId: rule.ruleId,
    });
    return httpClient
      .put<RuleDto>({
        url: urlPath,
        payload: rule,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async deleteRule(ruleId: string): Promise<void> {
    const urlPath = getUrl(URL_PATHS.RULES_ENGINE_BY_ID, {
      ruleId,
    });
    return httpClient
      .delete<void>({
        url: urlPath,
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getIncidentTypes(ruleCount = false): Promise<IncidentTypeDto[]> {
    const urlPath = ruleCount
      ? `${URL_PATHS.INCIDENT_TYPES_ENGINE}?countRules=${ruleCount}`
      : URL_PATHS.INCIDENT_TYPES_ENGINE;
    return httpClient
      .get<IncidentTypeDto[]>({
        url: urlPath,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async postIncidentType(
    incidentTypeRequestDTO: IncidentTypeRequestDTO
  ): Promise<IncidentTypeDto> {
    const urlPath = URL_PATHS.INCIDENT_TYPES_ENGINE;
    return httpClient
      .post<IncidentTypeDto>({
        url: urlPath,
        payload: incidentTypeRequestDTO,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async putIncidentType(
    incidentTypeId: string,
    incidentTypeRequestDTO: IncidentTypeRequestDTO
  ): Promise<IncidentTypeDto> {
    const urlPath = getUrl(URL_PATHS.INCIDENT_TYPES_ENGINE_BY_ID, {
      incidentTypeId,
    });
    return httpClient
      .put<IncidentTypeDto>({
        url: urlPath,
        payload: incidentTypeRequestDTO,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async deleteIncidentType(incidentTypeId: string): Promise<void> {
    const urlPath = getUrl(URL_PATHS.INCIDENT_TYPES_ENGINE_BY_ID, {
      incidentTypeId,
    });

    return httpClient
      .delete<void>({
        url: urlPath,
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async forwardIncidentType(
    incidentTypeId: string,
    incidentTypeIdToForward: string
  ): Promise<void> {
    const urlPath = getUrl(URL_PATHS.INCIDENT_TYPES_FORWARD_ENGINE_BY_ID, {
      incidentTypeId,
      incidentTypeIdToForward,
    });

    return httpClient
      .put<void>({
        url: urlPath,
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getIncidentTypeRules({
    incidentTypeId,
    index,
    pageSize,
  }: IncidentTypeRulesRequestDto): Promise<IncidentTypeRulesPageDto> {
    const urlPath = `${getUrl(URL_PATHS.INCIDENT_TYPE_RULES_ENGINE, {
      incidentTypeId,
    })}?pageIndex=${index}&&pageSize=${pageSize}`;
    return httpClient
      .get<IncidentTypeRulesPageDto>({
        url: urlPath,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async testRule(siteId: string, ruleId: string): Promise<void> {
    const urlPath = getUrl(URL_PATHS.RULES_ENGINE_CREATE_TEST_INCIDENT, {
      ruleId,
    });
    return httpClient
      .post<void>({
        url: urlPath,
        config: {
          params: {
            siteId,
          },
        },
      })
      .catch((error) => {
        throw Error(error);
      });
  }
}
