import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Components
import { SearchBar } from "dms-lib";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import CreateEdgeDeviceModal from "./createEdgeDeviceModal/CreateEdgeDeviceModal";
// Store
import {
  selectEdgeDevices,
  selectRoot,
  selectSites,
  useStoreDispatch,
} from "../../store";
import {
  edgeDevicesActions,
  resetEdgeDeviceToDefaults,
} from "../../store/edgeDevices";
import { ConfigModes, rootActions } from "../../store/root";
import { useTabActionsContext } from "../../contexts/TabActionsContext";
import { NewEdgeDeviceDTO } from "../../models/edgeDevices/NewEdgeDeviceDTO";

interface EdgeDevicesPanelHeaderProps {
  query: string;
  onSearchChangedCallback: (updatedQuery: string) => void;
}

const EdgeDevicesPanelHeader = ({
  query,
  onSearchChangedCallback,
}: EdgeDevicesPanelHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();
  const dispatch = useStoreDispatch();
  const { selectedSiteId } = useSelector(selectSites);
  const {
    edgeDevicesList,
    loadingEdgeDevicesCrud,
    currentPage,
    edgeDevicesError,
  } = useSelector(selectEdgeDevices);
  const [showCreateEdgeDeviceModal, setShowCreateEdgeDeviceModal] =
    useState(false);
  const {
    setConfigMainButtons,
    setEditActionsAllowed,
    setDisableAllButtons,
    setActivateAll,
  } = useTabActionsContext();
  const { configMode } = useSelector(selectRoot);

  const handleClose = () => {
    setShowCreateEdgeDeviceModal(false);
    dispatch(rootActions.activateReadOnlyMode());
  };

  const saveNewEdgeDevice = (newEdgeDeviceDTO: NewEdgeDeviceDTO) => {
    dispatch(
      edgeDevicesActions.createEdgeDevice({
        edgeDeviceName: NewEdgeDeviceDTO.parse(newEdgeDeviceDTO).name,
        siteId: selectedSiteId,
      })
    ).then(() => {
      if (!edgeDevicesError) {
        setShowCreateEdgeDeviceModal(false);
        dispatch(
          edgeDevicesActions.getAllEdgeDevices({
            page: currentPage,
            siteId: selectedSiteId,
          })
        );
        dispatch(rootActions.activateReadOnlyMode());
      }
    });
  };

  useEffect(() => {
    setDisableAllButtons(loadingEdgeDevicesCrud);
  }, [loadingEdgeDevicesCrud]);

  const handleCreate = () => {
    setShowCreateEdgeDeviceModal(true);
  };

  const strategyConfigMode = Object.freeze({
    [ConfigModes.READONLY]: null,
    [ConfigModes.CREATE]: handleCreate,
  });

  const {
    control: controlEdgeDevice,
    formState: { errors: errorsData, isValid },
    handleSubmit,
  } = useForm<NewEdgeDeviceDTO>({
    mode: "all",
    resolver: zodResolver(NewEdgeDeviceDTO), // We can use the dto itself to perform validation
    defaultValues: resetEdgeDeviceToDefaults,
  });

  /**
   * Activate Specific tab buttons
   */
  const initTabActionsConfig = () => {
    setConfigMainButtons({
      showCreateButton: true,
      showEditButton: false,
      showDeleteButton: false,
      showAssistantButton: false,
    });
    setActivateAll(false);
    setEditActionsAllowed(false);
  };

  // Subsystems Button Actions Initialization
  useEffect(() => {
    initTabActionsConfig();
    strategyConfigMode[configMode]?.();
  }, [configMode]);

  return (
    <div className="edge-devices-content__panel-top-div">
      <SearchBar
        id="edge-devices-search-bar"
        searchCallback={onSearchChangedCallback}
        value={query}
        placeholder={translate("edgeDevices.search")}
        className="search-bar"
        disabled={edgeDevicesList.length === 0}
      />
      <CreateEdgeDeviceModal
        control={controlEdgeDevice}
        errors={errorsData}
        isValid={isValid}
        open={showCreateEdgeDeviceModal}
        onCancelCallback={handleClose}
        onSaveCallback={handleSubmit(saveNewEdgeDevice)}
        saving={loadingEdgeDevicesCrud}
      />
    </div>
  );
};

export default EdgeDevicesPanelHeader;
