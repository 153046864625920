import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useI18n } from "compass-commons";
import StateService from "../../services/StateService";
import { useStateContext } from "../../contexts/StateContext";
import "./resourceLocationConfigurator.module.css";
import ResourceListPanel from "./components/resourceListPanel/ResourceListPanel";
import ResourceContentPanel from "./components/resourceContent/ResourceContentPanel";
// Context
import { useTabActionsContext } from "../../contexts/TabActionsContext";
// Hooks
import useTabActions from "../../hooks/useTabActions";
// Store
import {
  selectResources,
  selectRoot,
  selectSites,
  useStoreDispatch,
} from "../../store";
import { ConfigEditionModes, readOnlyMode } from "../../store/root";
import { resourcesActions } from "../../store/resources";
import FloorPlanService from "../../services/FloorPlanService";

const ResourceLocationConfigurator = (): JSX.Element => {
  const { t: translate } = useI18n();

  // REDUX
  const { configEditMode } = useSelector(selectRoot);
  const isReadOnlyMode = useSelector(readOnlyMode);
  const { selectedSiteId: siteId } = useSelector(selectSites);
  const { resourcesError, selectedResource } = useSelector(selectResources);
  const stateService: StateService = useStateContext();
  const dispatch = useStoreDispatch();

  // Actions Hook
  const { setReadOnlyMode } = useTabActions();

  // Actions Context
  const { setEditActionsAllowed, setConfigMainButtons, setActivateAll } =
    useTabActionsContext();

  const { alertSubject } = stateService;
  const [floorPlanList, setFloorPlanList] = useState([]);
  const [isLoadingFloorPlan, setIsLoadingFloorPlan] = useState(false);

  const errorCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  useEffect(() => {
    if (!resourcesError) return;
    errorCallback(`${translate(resourcesError.label)}`);
  }, [resourcesError]);

  const initialLoad = () => {
    FloorPlanService.getFloorPlansBySiteId(siteId)
      .then((fp) => {
        setFloorPlanList(fp);
      })
      .catch(() => {
        errorCallback(translate("site.failToLoad"));
        setIsLoadingFloorPlan(false);
      });
  };

  useEffect(() => {
    if (!siteId) return;
    initialLoad();
  }, [siteId]);

  const cancelSelection = useCallback(() => {
    initialLoad();
    setReadOnlyMode();
    if (selectedResource) {
      dispatch(resourcesActions.selectResource(selectedResource));
    }
  }, [siteId, selectedResource]);

  const handleSave = useCallback(() => {
    document.dispatchEvent(new CustomEvent("resourceLocationSave"));
  }, []);

  const strategyConfigEditionMode = Object.freeze({
    [ConfigEditionModes.CANCEL]: cancelSelection,
    [ConfigEditionModes.SAVE]: handleSave,
  });

  /**
   * Activate Specific tab buttons
   */
  const initTabActionsConfig = () => {
    setConfigMainButtons({
      showCreateButton: false,
      showDeleteButton: false,
      showEditButton: !!selectedResource,
      showAssistantButton: false,
    });
    setActivateAll(false);
    setEditActionsAllowed(true);
    setReadOnlyMode();
  };

  useEffect(() => {
    initTabActionsConfig();
  }, [selectedResource]);

  useEffect(() => {
    if (!isReadOnlyMode) strategyConfigEditionMode[configEditMode]?.();
  }, [configEditMode]);

  return isLoadingFloorPlan ? (
    <div data-cp="player-spinner" className="config-loading">
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <div data-cr="resource-location-configurator" className="config-main">
      <ResourceListPanel />
      <div className="config-resources__content config-content compass-rounded-corner">
        <ResourceContentPanel
          floorPlans={floorPlanList}
          errorCallback={errorCallback}
        />
      </div>
    </div>
  );
};

ResourceLocationConfigurator.defaultProps = {
  siteId: "1",
};

export default ResourceLocationConfigurator;
