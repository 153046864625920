import React from "react";
// Custom Components
import { useSelector } from "react-redux";
// Hooks
import { Button } from "dms-lib";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { useFeatureFlag, useI18n } from "compass-commons";
import useTabActions from "../../../../hooks/useTabActions";
// Contexts
import { useTabActionsContext } from "../../../../contexts/TabActionsContext";
import { selectSites } from "../../../../store";

import { CONFIG_OG_AI_FEATURE_FLAG } from "../../../../utils/Constants";

interface ConfigContentHeaderMainProps {
  disableActions: boolean;
}
/**
 * Component that handles some common actions to all the configuration sub navs
 * @returns JSX.Element
 */
const ConfigContentHeaderMain = ({
  disableActions,
}: ConfigContentHeaderMainProps): JSX.Element => {
  const { setCreateMode, setEditMode, setDeleteMode, setAICreateMode } =
    useTabActions();
  const { siteTabActive, selectedSiteId, selectedSite } =
    useSelector(selectSites);
  const {
    activateAll,
    mainActionsAllowed,
    configMainButtons: {
      showEditButton,
      showDeleteButton,
      showCreateButton,
      showAssistantButton,
    },
  } = useTabActionsContext();
  const { t: translate } = useI18n();
  const { enabled: ogAiFeatureFlag } = useFeatureFlag(
    appConfig,
    CONFIG_OG_AI_FEATURE_FLAG
  );

  const showCreateAssistantButton = showAssistantButton && ogAiFeatureFlag;

  return (
    <>
      {(activateAll || mainActionsAllowed || showCreateAssistantButton) && (
        <Button
          dataCr="ai-create-button"
          color="primary"
          variant="text"
          onClick={() => setAICreateMode()}
          className="ai-create-button"
        >
          {translate("operatorGuide.responsePlanAssistantButton")}
          <AutoAwesomeRoundedIcon />
        </Button>
      )}
      {(activateAll || mainActionsAllowed || showCreateButton) && (
        <Button
          id="add"
          color="primary"
          variant="contained"
          dataCr="add-button"
          disabled={disableActions}
          onClick={() => setCreateMode()}
          icon
        >
          <AddRoundedIcon />
        </Button>
      )}
      {(activateAll || mainActionsAllowed || showEditButton) && (
        <Button
          id="edit"
          dataCr="edit-button"
          color="primary"
          variant="contained"
          disabled={disableActions}
          onClick={() => setEditMode()}
          icon
        >
          <EditRoundedIcon />
        </Button>
      )}
      {(activateAll || mainActionsAllowed || showDeleteButton) && (
        <Button
          id="delete"
          dataCr="delete-button"
          color="primary"
          variant="contained"
          disabled={
            disableActions ||
            (siteTabActive && !selectedSite?.parentId) ||
            !selectedSiteId
          }
          onClick={() => setDeleteMode()}
          icon
        >
          <DeleteRoundedIcon />
        </Button>
      )}
    </>
  );
};

export default ConfigContentHeaderMain;
