// General
import React, { PropsWithChildren } from "react";
import { Provider, useDispatch } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
// Reducers
import sitesReducer, { SitesState } from "./sites";
import subsystemsReducer, { SubsystemsState } from "./subsystems";
import rootReducer, { ConfigRootState } from "./root";
import incidentsReducer, { IncidentState } from "./incidents";
import resourcesReduce, { ResourceState } from "./resources";
import edgeDevicesReducer, { EdgeDevicesState } from "./edgeDevices";
import operatorGuideReducer, { OperatorGuideState } from "./operatorGuide";
import resourceMappingReducer, {
  ResourceMappingState,
} from "./resourceMapping";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Create the root reducer separately, so we can extract the RootState type
const combinedReducer = combineReducers({
  incidents: incidentsReducer,
  root: rootReducer,
  sites: sitesReducer,
  subsystems: subsystemsReducer,
  resources: resourcesReduce,
  edgeDevices: edgeDevicesReducer,
  operatorGuide: operatorGuideReducer,
  resourceMapping: resourceMappingReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: combinedReducer,
    preloadedState,
  });
};

const store = setupStore();

export const StoreProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  return <Provider store={store}>{children}</Provider>;
};

// Root Selectors
export const selectIncidents = (state: RootState): IncidentState =>
  state.incidents;
export const selectSubsystems = (state: RootState): SubsystemsState =>
  state.subsystems;
export const selectSites = (state: RootState): SitesState => state.sites;
export const selectRoot = (state: RootState): ConfigRootState => state.root;
export const selectResources = (state: RootState): ResourceState =>
  state.resources;
export const selectEdgeDevices = (state: RootState): EdgeDevicesState =>
  state.edgeDevices;
export const selectOperatorGuide = (state: RootState): OperatorGuideState =>
  state.operatorGuide;
export const selectResourceMapping = (state: RootState): ResourceMappingState =>
  state.resourceMapping;

// Root dispatcher
export const useStoreDispatch = (): StoreDispatch =>
  useDispatch<StoreDispatch>();

export type RootState = ReturnType<typeof combinedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type StoreDispatch = typeof store.dispatch;

export default store;
