import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import MuiFormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import { Controller, useFormContext } from "react-hook-form";
import { useI18n } from "compass-commons";
import { TextField } from "dms-lib";

const FormLabel = styled(MuiFormLabel)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: theme.typography.fontWeightBold,
}));

const defaultValues = {
  message: "",
};

type FormValues = typeof defaultValues;
type Props = { loading: boolean };

const FormAiAssistedOg = ({ loading }: Props): JSX.Element => {
  const { t } = useI18n();
  const { control } = useFormContext<FormValues>();

  return (
    <form>
      <Controller
        name="message"
        control={control}
        render={({ field, fieldState }) => (
          <>
            <FormLabel error={!!fieldState.error}>
              {t("operatorGuide.writeOg")}
            </FormLabel>
            <TextField
              {...field}
              placeholder={t("operatorGuide.promptPlaceholder")}
              multiline
              error={!!fieldState.error}
              disabled={loading}
              size="small"
              fullWidth
            />
            {fieldState.error && (
              <FormHelperText error={!!fieldState.error}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </form>
  );
};

export { FormAiAssistedOg as default, defaultValues, FormValues };
