// General
import { createSlice } from "@reduxjs/toolkit";
// Reducers
import resourceMappingExtraReducers from "./extraReducers";
// Actions
import resourceMappingsExtraActions from "./extraActions";
// Models
import { ResourceMappingDto } from "../../models/resourceMapping/ResourceMappingDto";
import { ResourceMappingConnectionsDto } from "../../models/resourceMapping/ResourceMappingConnectionsDto";
import { ResourceMappingEventTypesDto } from "../../models/resourceMapping/ResourceMappingEventTypesDto";

/* eslint-disable no-param-reassign */

type ResourceMappingErrorType = { label: string; message: string };

export type ResourceMappingState = {
  connections: ResourceMappingConnectionsDto;
  loadingConnections: boolean;
  connectionsError?: ResourceMappingErrorType;
  eventTypes: ResourceMappingEventTypesDto;
  loadingEventTypes: boolean;
  eventTypesError?: ResourceMappingErrorType;
  resourceMappingList: ResourceMappingDto[];
  loadingResourceMappingList: boolean;
  resourceMappingError?: ResourceMappingErrorType;
};

const SLICE_NAME = "resourceMapping";

export const PROP_SEARCH_TERM = "name";

const initialState: ResourceMappingState = {
  connections: { connections: [] },
  loadingConnections: false,
  connectionsError: undefined,
  eventTypes: [],
  loadingEventTypes: false,
  eventTypesError: undefined,
  resourceMappingList: [],
  loadingResourceMappingList: false,
  resourceMappingError: undefined,
};

/**
 * Slice to manipulate all operations related to sites
 */
export const resourceMappingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: resourceMappingExtraReducers,
});

// Action creators are generated for each case reducer function
export const resourceMappingActions = {
  ...resourceMappingSlice.actions,
  ...resourceMappingsExtraActions,
};

export default resourceMappingSlice.reducer;
