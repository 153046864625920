// General
import React from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useI18n } from "compass-commons";
// Components
import { AutoComplete } from "dms-lib";
// Styles
import "../behaviourTriggerPanel.module.css";
// Models

import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";
import {
  ResourceMappingEventType,
  ResourceMappingEventTypesDto,
} from "../../../../../models/resourceMapping/ResourceMappingEventTypesDto";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";
import { RuleDto } from "../../../../../models/incidents/RuleDto";

interface BehaviourTriggerEventTypeProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  errors?: FormErrors<FormRuleDto>;
  eventTypes: ResourceMappingEventTypesDto;
  loading: boolean;
  setFieldValue: UseFormSetValue<RuleDto>;
  watchField: UseFormWatch<RuleDto>;
}

const BehaviourTriggerEventType = (
  props: BehaviourTriggerEventTypeProps
): JSX.Element => {
  const {
    ruleId,
    readOnlyMode,
    control,
    eventTypes,
    loading,
    errors,
    setFieldValue,
    watchField,
  } = props;
  const { t: translate } = useI18n();

  return (
    <div className="config-incidents__trigger-input__section">
      <span>{translate("behaviors.triggerFieldsDescription.eventType")}</span>
      <div className="config-incidents__trigger-input__controller">
        <Controller
          name="trigger.eventType"
          control={control}
          render={({ field: { onChange, ...field } }) => {
            return (
              <AutoComplete<ResourceMappingEventType>
                {...field}
                value={field.value as ResourceMappingEventType}
                dataCr="incident-trigger-event-type"
                options={
                  eventTypes?.map(({ id, name }) => {
                    return { id, name };
                  }) || []
                }
                getOptionLabel={(option) => option.name || ""}
                id={`trigger-event-type-autocomplete-${ruleId}`}
                disabled={readOnlyMode}
                loading={loading}
                placeholder={translate(
                  "behaviors.triggerFieldsDescription.eventTypePlaceholder"
                )}
                onChangeCallback={(data: ResourceMappingEventType, _e) => {
                  setFieldValue("trigger.eventTypeId", data?.id || null);
                  setFieldValue("trigger.eventTypeName", data?.name || null);
                  onChange(data);
                  if (
                    watchField("behaviour.description") ===
                    translate("behaviors.placeholders.newBehavior")
                  ) {
                    setFieldValue("behaviour.description", data?.name || "");
                  }
                }}
                error={!!errors.trigger?.eventType}
                errorMessage={translate(
                  errors.trigger?.eventTypeName?.message ||
                    errors.trigger?.eventType?.message ||
                    errors.trigger?.message,
                  { label: translate("behaviors.fields.eventTypeName") }
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default BehaviourTriggerEventType;
