import React from "react";
import "./searchBar.module.css";
import { InputAdornment, TextField } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

interface SearchBarProps {
  searchCallback: (searchInput: string) => void;
  dataCr?: string;
  placeholder?: string;
  value?: string;
}

const SearchBar = (props: SearchBarProps): JSX.Element => {
  const {
    searchCallback,
    dataCr = "search-bar",
    placeholder = "Search...",
    value,
  } = props;
  return (
    <div className="config-search-bar-main" data-cr={dataCr}>
      <TextField
        size="small"
        placeholder={placeholder}
        {...(value !== undefined ? { value } : {})}
        data-cr={`${dataCr}-input`}
        onChange={(e) => searchCallback(e?.target?.value || "")}
        sx={{ width: "100%", fontSize: "14px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchBar;
