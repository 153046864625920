import { array, boolean, object, string, z, ZodIssueCode } from "zod";
import RuleStringConstraint from "../RuleStringConstraint";
import RuleStringOptional from "../RuleStringOptional";

const connectionRequiredErrorMessage =
  "behaviors.propertiesFieldsValidation.required";
const EventTypeSubsystemSchema = object(
  {
    id: RuleStringConstraint,
    name: RuleStringConstraint,
  },
  {
    required_error: connectionRequiredErrorMessage,
    invalid_type_error: "behaviors.propertiesFieldsValidation.required",
  }
);
const EventTypeSchema = object(
  {
    id: RuleStringConstraint,
    name: RuleStringConstraint,
  },
  {
    required_error: connectionRequiredErrorMessage,
    invalid_type_error: "behaviors.propertiesFieldsValidation.required",
  }
);

export const FormTriggerDtoSchema = object({
  eventTypeName: RuleStringOptional,
  eventTypeId: RuleStringOptional,
  eventType: EventTypeSchema.optional().nullable(),
  eventTypeSubsystemId: RuleStringOptional,
  eventTypeSubsystemName: RuleStringOptional,
  disabled: boolean(),
  commentForTriggerBeingDisabled: RuleStringOptional,
  triggerResourceMappingDTOs: array(
    object({
      id: RuleStringConstraint,
      name: RuleStringConstraint,
    })
  ),
  // Form specific fields
  eventTypeSubsystem: EventTypeSubsystemSchema.optional().nullable(),
  daysOfWeek: array(string())
    .min(1, "behaviors.propertiesFieldsValidation.weekDays.atLeastOne")
    .refine((arr) => arr.every((txt: string) => txt.trim().length >= 1), {
      message: "behaviors.propertiesFieldsValidation.required",
      path: ["daysOfWeek"],
    }),
  availableForOnDemand: boolean(),
}).superRefine((data, ctx) => {
  if (!data.availableForOnDemand) {
    if (!data.eventTypeId) {
      ctx.addIssue({
        path: ["eventType"],
        message: "behaviors.propertiesFieldsValidation.required",
        code: ZodIssueCode.custom,
      });
    }

    if (!data.eventTypeName) {
      ctx.addIssue({
        path: ["eventType"],
        message: "behaviors.propertiesFieldsValidation.required",
        code: ZodIssueCode.custom,
      });
    }

    if (!data.eventTypeSubsystemId) {
      ctx.addIssue({
        path: ["eventTypeSubsystem"],
        message: "behaviors.propertiesFieldsValidation.required",
        code: ZodIssueCode.custom,
      });
    }

    if (!data.eventTypeSubsystemName) {
      ctx.addIssue({
        path: ["eventTypeSubsystem"],
        message: "behaviors.propertiesFieldsValidation.required",
        code: ZodIssueCode.custom,
      });
    }
  }
});

export type FormTriggerDto = z.infer<typeof FormTriggerDtoSchema>;
