import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// Styles
import "./ConfigLayout.module.scss";
// Material UI
// Custom Components
import { CircularProgress } from "@mui/material";
import { TabActionsProvider } from "../../contexts/TabActionsContext";
import ConfigContentHeader from "../configContentHeader/ConfigContentHeader";
import GeneralConfigurator from "../generalConfiguration/GeneralConfigurator";
// Store
import useNavigateToSite from "../../hooks/useNavigateToSite";
import { selectSites, useStoreDispatch } from "../../store";
import { sitesActions } from "../../store/sites";
import { rootActions } from "../../store/root";
import { MenuTabRoute } from "../../models/configMenu/MenuTabRoute";
import { CONFIG_LAYOUT_CLASS_NAME } from "../../utils/Constants";

/**
 * Component responsible for the configuration page layout
 * First component that has the information about the site Id.
 * If no site id is passed then it will be initialized later with the site tree
 * @returns JSX.Element
 */
const ConfigLayoutConfiguration = (): JSX.Element => {
  const { siteRoot } = useSelector(selectSites);
  const dispatch = useStoreDispatch();
  const navigateToSite = useNavigateToSite();

  useEffect(() => {
    dispatch(sitesActions.getAll());
    dispatch(rootActions.changeTab(MenuTabRoute.Generic));
  }, []);

  useEffect(() => {
    if (!siteRoot.data) return;
    navigateToSite(siteRoot.data[0].id);
  }, [siteRoot.data]);

  return (
    <div className={CONFIG_LAYOUT_CLASS_NAME} data-cr="configuration">
      {!siteRoot.data ? (
        <div className="config-layout__spinner" data-cp="player-spinner">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <TabActionsProvider>
          <section className="config-layout__main">
            <div className="config-content">
              <ConfigContentHeader />
              <section className="config-content__panel">
                <GeneralConfigurator />
              </section>
            </div>
          </section>
        </TabActionsProvider>
      )}
    </div>
  );
};

export default ConfigLayoutConfiguration;
